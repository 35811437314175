import React , { useState, useEffect, useCallback } from 'react';
import { Link, graphql } from 'gatsby';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { Script } from "gatsby";
import { 
  LayoutLp,
  SEO,
  AssetImage,
  CLpModalNewyear,
} from '../../../components/_index';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/newyear2025.scss';
import { useInView } from "react-intersection-observer";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  // const [scrollFlag, setScrollFlag] = useState<Boolean>(false);
  // useEffect(() => {
  //   window.addEventListener('scroll', scrollEvent);
  //   return () => window.removeEventListener('scroll', scrollEvent);
  // }, []);
  // const scrollEvent = useCallback(() => {
  //   if (
  //     window.scrollY > document.querySelectorAll('.l_headerLpNewyear')[0].clientHeight
  //   ) {
  //     document.querySelectorAll('.lp_newYearFooter')[0].classList.remove('disnon');
  //   }
  // }, [scrollFlag]);

  // Swiper
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [thumbsSwiper1, setThumbsSwiper1] = useState(null);
  const [thumbsSwiper2, setThumbsSwiper2] = useState(null);
  const [thumbsSwiper3, setThumbsSwiper3] = useState(null);

  // modalSwiper
  const [thumbsSwiper4, setThumbsSwiper4] = useState(null);

  // modal
  // event
  const [modalFlagEvent, setModalFlagEvent] = useState(false);
  // osechi
  const [modalFlag, setModalFlag] = useState(false);
  const [modalFlag02, setModalFlag02] = useState(false);
  const [modalFlag03, setModalFlag03] = useState(false);

  const openModalEvent = (e: any) => {
    e.stopPropagation();
    setModalFlagEvent(!modalFlagEvent);
  };
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  const openModal02 = (e: any) => {
    e.stopPropagation();
    setModalFlag02(!modalFlag02);
  };
  const openModal03 = (e: any) => {
    e.stopPropagation();
    setModalFlag03(!modalFlag03);
  };


  // アコーディオン
  const defaultFlag = false;
  const [openFlag, setOpenFlag] = useState(defaultFlag);
  const toggleClick = () => {
    setOpenFlag(!openFlag)
  }

  const useCustomInView = () => {
    return useInView({
      threshold: 0.3,
      triggerOnce: true,
    });
  };
  const [refPc1, inViewPc1] = useCustomInView();
  const [refPc2, inViewPc2] = useCustomInView();
  const [refPc3, inViewPc3] = useCustomInView();
  const [refPc4, inViewPc4] = useCustomInView();
  const [refPc5, inViewPc5] = useCustomInView();

  const [refSp1, inViewSp1] = useCustomInView();
  const [refSp2, inViewSp2] = useCustomInView();
  const [refSp3, inViewSp3] = useCustomInView();
  const [refSp4, inViewSp4] = useCustomInView();
  const [refSp5, inViewSp5] = useCustomInView();
  const [refSp6, inViewSp6] = useCustomInView();

  return (
    <LayoutLp isNewyear={true}>
      <Script dangerouslySetInnerHTML={{
        __html: `
(function() {
    const tabList = document.querySelectorAll('.lp_tabItem');
    const tabListSp = document.querySelectorAll('.lp_tabItemSp');
    const tabContent = document.querySelectorAll('.lp_tabContentsItem');
    const tabContentSp = document.querySelectorAll('.lp_tabContentsItemSp');

    function tabSwitch() {
      document.querySelectorAll('.active')[0].classList.remove('active');
      this.classList.add('active');
      document.querySelectorAll('.show')[0].classList.remove('show');
      const aryTabs = Array.prototype.slice.call(tabList);
      const index = aryTabs.indexOf(this);
      tabContent[index].classList.add('show');
    };
    for (let i = 0; i < tabList.length; i++) {
      tabList[i].addEventListener('click', tabSwitch);
    }

    function tabSwitchSp() {
      document.querySelectorAll('.activeSp')[0].classList.remove('activeSp');
      this.classList.add('activeSp');
      document.querySelectorAll('.showSp')[0].classList.remove('showSp');
      const aryTabs = Array.prototype.slice.call(tabListSp);
      const index = aryTabs.indexOf(this);
      tabContentSp[index].classList.add('showSp');
    };
    for (let i = 0; i < tabListSp.length; i++) {
      tabListSp[i].addEventListener('click', tabSwitchSp);
    }
})(document)

        `}} />
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        ogimage={`${process.env.BASE_URL}/assets/images/sp/newyear2025/icon/ogimage.png`}
      />
      <div className="lp_newyear2025">
        <section className="lp_kv">
          <figure className="imgWrapper u_pc">
            <AssetImage
              src="/assets/images/sp/newyear2025/mv.png"
              alt=""
              loading="lazy"
            />
          </figure>
          <figure className="imgWrapper u_sp">
            <AssetImage
              src="/assets/images/sp/newyear2025/mv_sp.png"
              alt=""
              loading="lazy"
            />
          </figure>
        </section>
        <section className="lp_sect01">
          <div ref={refPc1} className={`plum_1-1 u_pc ${inViewPc1 ? "animation" : ""}`}></div>
          <div ref={refPc2} className={`plum_1-2 u_pc ${inViewPc2 ? "animation" : ""}`}></div>
          <div ref={refSp1} className={`plum_1-1_sp u_sp ${inViewSp1 ? "animation" : ""}`}></div>
          <div ref={refSp2} className={`plum_1-2_sp u_sp ${inViewSp2 ? "animation" : ""}`}></div>
          <div className="lp_menu">
            <div className="lp_menuTextBox">
              <div className="lp_menuText">
                お正月の贅を尽くしたご宿泊プランや食卓を華麗に彩るおせち料理。<br className="u_pc" />新年の幕開けに上質な空間と心を込めたおもてなしでお迎えいたします。<br />
                ロイヤルパークホテルで、心華やぐ一年の始まりをお楽しみください。
              </div>
            </div>
          </div>
          <div className="lp_menuList">
            <div className="lp_menuItem">
              <img src="/assets/images/sp/newyear2025/icon/restaurantbox.png" alt=""/>
              <div className="lp_menuItemText">レストラン</div>
            </div>
            <a href="#plan">
              <div className="lp_menuItem">
                <img src="/assets/images/sp/newyear2025/icon/planbox.png" alt=""/>
                <div className="lp_menuItemText">ご宿泊プラン</div>
              </div>
            </a>
            <div className="lp_menuItem">
              <img src="/assets/images/sp/newyear2025/icon/eventbox.png" alt=""/>
              <div className="lp_menuItemText">イベント</div>
            </div>
            <a href="#osechi">
              <div className="lp_menuItem">
                <img src="/assets/images/sp/newyear2025/icon/osechibox.png" alt=""/>
                <div className="lp_menuItemText">おせち料理</div>
              </div>
            </a>
          </div>
          <div className="lp_sect01Bg">
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2025/icon/plan_icon.png" alt=""/>
              <div className="lp_sectTitle" id="plan">
                ご宿泊プラン
              </div>
            </div>
            <div className="lp_tabContents">
              <div className="lp_tabContentsItem show">
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper swiper1"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/stay/stay_slide_01.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/stay/stay_slide_02.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/stay/stay_slide_03.png" />
                  </SwiperSlide>
                </Swiper>
                <Swiper
                  loop={true}
                  spaceBetween={0}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <div className="lp_swiperContents1">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/stay/stay_slide_01.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              CHIC TOKYO STAY
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              寿 “KOTOBUKI”
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              年末から正月三が日まで、年末年始を満喫できる贅を尽くしたプラン。お正月ならではのイベントや特別メニューをご家族や大切な方とともにご堪能いただけます。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月31日（火）<br />
                                ～2025年1月3日（金） 3泊4日プラン 
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金例</div>
                              <div className="lp_swiperTextContentsListText">
                                スタンダートフロア スタンダートツイン<br />
                                1室2名様ご利用時（夕・朝食付）<br />
                                ザ クラブ会員：お一人様　200,000円<br />
                                一般　　　　 ：お一人様　205,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※朝食付プランもご用意しております。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/stay/plan/s56xdhtwezn/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents1">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/stay/stay_slide_02.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              CHIC TOKYO STAY
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              初春 “HATSUHARU”
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              年末年始を優雅にお過ごしいただけるプラン。年越しそばやおせち料理をはじめ、ホテル館内で行われる正月イベントなどもご満喫いただけます。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月31日（火）<br />
                                ～2025年1月2日（木） 2泊3日プラン
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金例</div>
                              <div className="lp_swiperTextContentsListText">
                                スタンダートフロア スタンダートツイン<br />
                                1室2名様ご利用時（夕・朝食付）<br />
                                ザ クラブ会員：お一人様　155,000円<br />
                                一般　　　　 ：お一人様　160,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※朝食付プランもご用意しております。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/stay/plan/s56xdhtwezn/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents1">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/stay/stay_slide_03.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              CHIC TOKYO STAY
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春 “SHINSHUN”
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              正月三が日をゆったりとお寛ぎいただけるプラン。お正月ならではのイベントや特別メニューを楽しみながらご家族での穏やかなひとときをお過ごしください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）<br />
                                ～1月3日（金） 2泊3日プラン
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金例</div>
                              <div className="lp_swiperTextContentsListText">
                                スタンダードフロア　スタンダードツイン<br />
                                1室2名様ご利用時（夕・朝食付）<br />
                                ザ クラブ会員：お一人様　128,000円<br />
                                一般　　　　 ：お一人様　133,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※朝食付プランもご用意しております。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/stay/plan/s56xdhtwezn/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <section className="lp_sect02" id="osechi">
          <div ref={refPc3} className={`plum_2-1 u_pc ${inViewPc3 ? "animation" : ""}`}></div>
          <div ref={refPc4} className={`plum_2-2 u_pc ${inViewPc4 ? "animation" : ""}`}></div>
          <div ref={refPc5} className={`plum_2-3 u_pc ${inViewPc5 ? "animation" : ""}`}></div>
          <div ref={refSp3} className={`plum_2-1_sp u_sp ${inViewSp3 ? "animation" : ""}`}></div>
          <div ref={refSp4} className={`plum_2-2_sp u_sp ${inViewSp4 ? "animation" : ""}`}></div>
          <div className="lp_sect02Bg">
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2025/icon/osechi_icon.png" alt=""/>
              <div className="lp_sectTitle lp_sectTitle02">
                おせち料理
              </div>
            </div>
            <div className="lp_tabContents">
              <div className="lp_tabContentsItem show">
                <Swiper
                  onSwiper={setThumbsSwiper2}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper swiper3"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/osechi/osechi_slide_01.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/osechi/osechi_slide_02.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/osechi/osechi_slide_03.png" />
                  </SwiperSlide>
                </Swiper>
                <Swiper
                  loop={true}
                  spaceBetween={0}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper2 }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <div className="lp_swiperContentsWrap">
                      <div className="lp_swiperContents">
                        <div className="lp_swiperImg lp_swiperImgLeft">
                          <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/osechi/osechi_slide_01.png" alt=""></AssetImage>
                        </div>
                        <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                          <div className="">
                            <div className="lp_swiperTextContentsTitle">
                              <div className="lp_swiperTextContentsTitle--bold">
                                想咲おせち
                              </div>
                              <div className="lp_swiperTextContentsTitle--sub">
                                日本料理
                              </div>
                            </div>
                            <div className="px-1">
                              <div className="lp_swiperTextContentsList2Text">
                                日本料理長 岩田 好輝の想いが咲き誇る贅の限りを尽くした「想咲おせち」。縁起を担ぐ品々を丁寧に仕込み、美しく盛合せた三段重です。
                              </div>
                            </div>
                            <ul className="mt-30">
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">ご予約期間</div>
                                <div className="lp_swiperTextContentsList2Text">
                                  2024年10月1日（火）<br />
                                  ～2024年12月18日（水）19:00
                                </div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">限定数</div>
                                <div className="lp_swiperTextContentsList2Text">10個</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">料金</div>
                                <div className="lp_swiperTextContentsList2Text">108,000円</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--small">
                                  ※要予約
                                </div>
                              </li>
                            </ul>
                          </div>
                          <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b3347257c18e0835070b52&num_people=1&start_date=2024-12-31/" target="_blank">
                            <div className="lp_swiperTextContentsButton">
                              Web予約はこちら →
                            </div>
                          </a>
                          <div className="lp_swiperText--small">お電話、FAXでのご予約は<a href="https://www.rph.co.jp/private/os9yvr2b0/" target="_blank">こちら</a></div>
                        </div>
                      </div>
                      <div className="lp_swiperContentsMenuWrap">
                        <div className="lp_swiperContentsMenu u_pc">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_01.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lp_swiperContentsMenu u_sp">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_01_sp.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContentsWrap">
                      <div className="lp_swiperContents">
                        <div className="lp_swiperImg lp_swiperImgLeft">
                          <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/osechi/osechi_slide_02.png" alt=""></AssetImage>
                        </div>
                        <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                          <div className="">
                            <div className="lp_swiperTextContentsTitle">
                              <div className="lp_swiperTextContentsTitle--bold">
                                おせち料理 三段
                              </div>
                              <div className="lp_swiperTextContentsTitle--sub">
                                日本料理・フランス料理・中国料理
                              </div>
                            </div>
                            <div className="px-1">
                              <div className="lp_swiperTextContentsList2Text">
                                当ホテルならではの幅広い世代の方にお楽しみいただける和洋中折衷の三段重。多彩な美味とともに、新年の幕開けをお楽しみください。
                              </div>
                            </div>
                            <ul className="mt-30">
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">ご予約期間</div>
                                <div className="lp_swiperTextContentsList2Text">
                                  2024年10月1日（火）<br />
                                  ～2024年12月18日（水）19:00 
                                </div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">限定数</div>
                                <div className="lp_swiperTextContentsList2Text">100個</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">料金</div>
                                <div className="lp_swiperTextContentsList2Text">59,400円</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--small">
                                  ※要予約
                                </div>
                              </li>
                            </ul>
                          </div>
                          <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b305d557c18e0835070132&num_people=1&start_date=2024-12-31" target="_blank">
                            <div className="lp_swiperTextContentsButton">
                              Web予約はこちら →
                            </div>
                          </a>
                          <div className="lp_swiperText--small">お電話、FAXでのご予約は<a href="https://www.rph.co.jp/private/os9yvr2b0/" target="_blank">こちら</a></div>
                        </div>
                      </div>
                      <div className="lp_swiperContentsMenuWrap">
                        <div className="lp_swiperContentsMenu u_pc">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_02.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lp_swiperContentsMenu u_sp">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_02_sp.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContentsWrap">
                      <div className="lp_swiperContents">
                        <div className="lp_swiperImg lp_swiperImgLeft">
                          <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/osechi/osechi_slide_03.png" alt=""></AssetImage>
                        </div>
                        <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                          <div className="">
                            <div className="lp_swiperTextContentsTitle">
                              <div className="lp_swiperTextContentsTitle--bold">
                                おせち料理 二段
                              </div>
                              <div className="lp_swiperTextContentsTitle--sub">
                                日本料理
                              </div>
                            </div>
                            <div className="px-1">
                              <div className="lp_swiperTextContentsList2Text">
                                おせち料理の定番の品々を種類豊富に詰合せ、手間を惜しまず匠の技で味わい深く仕上げました。少人数のご家庭にも人気です。
                              </div>
                            </div>
                            <ul className="mt-30">
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">ご予約期間</div>
                                <div className="lp_swiperTextContentsList2Text">
                                  2024年10月1日（火）<br />
                                  ～2024年12月18日（水）19:00
                                </div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">限定数</div>
                                <div className="lp_swiperTextContentsList2Text">100個</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">料金</div>
                                <div className="lp_swiperTextContentsList2Text">41,040円</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--small">
                                  ※要予約
                                </div>
                              </li>
                            </ul>
                          </div>
                          <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b31746af3d63bd6fbb9ac4&num_people=1&start_date=2024-12-31" target="_blank">
                            <div className="lp_swiperTextContentsButton">
                              Web予約はこちら →
                            </div>
                          </a>
                          <div className="lp_swiperText--small">お電話、FAXでのご予約は<a href="https://www.rph.co.jp/private/os9yvr2b0/" target="_blank">こちら</a></div>
                        </div>
                      </div>
                      <div className="lp_swiperContentsMenuWrap">
                        <div className="lp_swiperContentsMenu u_pc">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_03.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lp_swiperContentsMenu u_sp">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_03_sp.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="lp_sect03" id="event">
          <div className="item3-1"></div>
          <div className="item3-2"></div>
          <div className="item3-3"></div>
          <div className="lp_sect03Bg">
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2024/icon/icon_event.png" alt=""/>
              <div className="lp_sectTitle lp_sectTitle03">
                イベント
              </div>
            </div>
            <div className="lp_sectTitleSub">Event</div>
            <div className="lp_tabList u_pc"></div>
            <div className="lp_tabContentsItem u_pc show">
              <Swiper
                onSwiper={setThumbsSwiper3}
                loop={true}
                spaceBetween={10}
                slidesPerView={8}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper swiper1"
              >
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event2.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event3.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event4.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event5.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event6.png" />
                </SwiperSlide>
              </Swiper>
              <div className="border"></div>
              <Swiper
                loop={true}
                spaceBetween={0}
                navigation={false}
                thumbs={{ swiper: thumbsSwiper3 }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
              >
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          1F ロビー／2F 宴会場<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            縁日フェア
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）、1月2日（火）<br />
                              10:00～18:00
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a href="https://www.rph.co.jp/information/tio5r18mt/" target="_blank">
                        <div className="lp_swiperTextContentsButton">
                          詳細はこちら →
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event2.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          3F 宴会場 ロイヤルホール<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            ホテルでおせち
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）<br />
                              7:30～10:30（L.O. 10:00）
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">料金</div>
                            <div className="lp_swiperTextContentsListText">
                              お一人様　7,590円
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a href="https://www.rph.co.jp/information/8dxvy4b5gly/" target="_blank">
                        <div className="lp_swiperTextContentsButton">
                          詳細はこちら →
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event3.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          3F 宴会場 ロイヤルホール<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            新春 満福団らんブッフェ
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）、1月2日（火）<br />
                              11:30～15:00（L.O. 14:30）<br />
                              17:00～21:00（L.O. 20:30）
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">料金</div>
                            <div className="lp_swiperTextContentsListText">
                              お一人様　10,120円
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--small">
                              ※ お子様、シニア料金あり
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a href="https://www.rph.co.jp/information/izykgyh44t/" target="_blank">
                        <div className="lp_swiperTextContentsButton">
                          詳細はこちら →
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event4.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          20F 宴会場 パラッツオ<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            新春 パラッツオランチブッフェ
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）、1月2日（火）<br />
                              1部 11:00～13:00（L.O. 12:00）／<br />
                              2部 13:30～15:30（L.O. 14:30）
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">料金</div>
                            <div className="lp_swiperTextContentsListText">
                              お一人様　10,752円
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--small">
                              ※ お子様、シニア料金あり
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a href="https://www.rph.co.jp/information/k14zroqz1e/" target="_blank">
                        <div className="lp_swiperTextContentsButton">
                          詳細はこちら →
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event5.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          1F ロビー<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            もちつき
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText">
                              お正月飾りに彩られたロビーで、一年の幸福を祈願しながら、お囃子や太鼓とともに威勢よくお餅をつきあげます。
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）<br />
                              11:00～／14:00～ 各回約30分
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--small">
                              ※ ご予約不要、鑑賞無料
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event6.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          1F ロビー<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            福袋
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）、1月2日（火）<br />
                              10:00～18:00
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">料金</div>
                            <div className="lp_swiperTextContentsListText">
                              30,000円～
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--small">
                              ※ 料金には消費税が含まれます。
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a href="https://www.rph.co.jp/information/3uvf8ekzf4i/" target="_blank">
                        <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="lp_tabContents u_sp">
              <div className="lp_contentsWrap">
                <a href="https://www.rph.co.jp/information/tio5r18mt/" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        縁日フェア
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）、<br />
                        1月2日（火）
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.rph.co.jp/information/8dxvy4b5gly/" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event2.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        ホテルでおせち
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.rph.co.jp/information/izykgyh44t/" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event3.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        新春 満福団らんブッフェ
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）、<br />
                        1月2日（火）
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.rph.co.jp/information/k14zroqz1e/" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event4.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        新春 パラッツオランチブッフェ
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）、<br />
                        1月2日（火）
                      </div>
                    </div>
                  </div>
                </a>
                <button
                  onClick={openModalEvent}
                >
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event5.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        もちつき
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）
                      </div>
                    </div>
                  </div>
                </button>
                <a href="https://www.rph.co.jp/information/3uvf8ekzf4i/" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event6.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        福袋
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）、<br />
                        1月2日（火）
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="lp_sect04" id="osechi">
          <div className="item4-1"></div>
          <div className="item4-2 u_pc"></div>
          <div className="item4-3"></div>
          <div className="item4-4"></div>
          <div className="lp_sect04Bg">
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2024/icon/icon_osechi.png" alt=""/>
              <div className="lp_sectTitle lp_sectTitle04">
                おせち料理
              </div>
            </div>
            <div className="lp_sectTitleSub">Osechi</div>
            <div className="lp_sectContents">
              <button
                onClick={openModal}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/newyear2024/osechi1.png" alt=""/>
                  <div className="lp_sectContentsItemText">
                    想咲おせち<br />
                    <div className="lp_sectContentsItemText--small">日本料理</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal02}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/newyear2024/osechi2.png" alt=""/>
                  <div className="lp_sectContentsItemText">
                    おせち料理 三段<br />
                    <div className="lp_sectContentsItemText--small">日本料理・フランス料理・中国料理</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal03}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/newyear2024/osechi3.png" alt=""/>
                  <div className="lp_sectContentsItemText">
                    おせち料理 二段<br />
                    <div className="lp_sectContentsItemText--small">日本料理</div>
                  </div>
                </div>
              </button>
            </div>
            <div className="lp_sectButtonWrap">
              <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                <div className="lp_sectButton">
                  おせち料理のご予約はこちら →
                </div>
              </a>
            </div>
          </div>
        </section> */}
        <section className="lp_sect05" id="">
          <div ref={refSp5} className={`plum_5-1_sp u_sp ${inViewSp5 ? "animation" : ""}`}></div>
          <div ref={refSp6} className={`plum_5-2_sp u_sp ${inViewSp6 ? "animation" : ""}`}></div>
          <div className="lp_attentionWrap">
            <ul className="lp_attentionText">
              <li>※レストランプラン、イベントの料金には消費税・サービス料が含まれます。</li>
              <li>※ご宿泊プランの料金には、消費税・サービス料・夕朝食・東京都宿泊税が含まれます。</li>
              <li>※おせち料理の料金には消費税（8％）が含まれます。</li>
              <li>※⾷材の⼊荷状況により、内容を変更させていただく場合がございます。</li>
              <li>※限定商品のため、販売終了の際はご容赦ください。</li>
              <li>※掲載の写真は実際と異なる場合がございます。</li>
            </ul>
          </div>
        </section>
        <section className="lp_comingsoonWrap">
          <img className="u_pc" src="/assets/images/sp/newyear2025/comingsoon_bg.png" alt=""/>
          <img className="u_sp" src="/assets/images/sp/newyear2025/comingsoon_bg_sp.png" alt=""/>
          <div className="lp_comingsoonTitle">
            <div className="lp_comingsoonText">お正月ならではのレストランメニュー・イベントをご準備しております。</div>
          </div>
        </section>
        <section className="lp_snsBlock">
          <a href="https://www.instagram.com/royalparkhotel.tokyo/" target="_blank">
            <div className="lp_snsBlockSubTitle">\ Follow me /</div>
            <div className="lp_snsBlockTitle">Instagram</div>
            <img src="/assets/images/sp/newyear2025/icon/Instagram_icon.png" alt="Instagram_icon"/>
            <div className="lp_snsBlockAddress u_pc">@royalparkhotel.tokyo</div>
            <div className="lp_snsBlockText">
              ロイヤルパークホテルのご案内や、イベント、最新情報をご紹介！<br className="u_pc" />
              皆様からのたくさんのフォローをお待ちしております。
            </div>
            <div className="lp_snsBlockAddress u_sp">@royalparkhotel.tokyo</div>
          </a>
        </section>
        
        <div className="lp_newYearFooter disnon u_sp">
          <ul>
            <li>
              <a href="#restaurant">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/restaurant_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_newYearFooterText">レストラン</div>
              </a>
            </li>
            <li>
              <a href="#stay">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/stay_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              <div className="lp_newYearFooterText">ステイ</div>
              </a>
            </li>
            <li>
              <a href="#event">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/event_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_newYearFooterText">イベント</div>
              </a>
            </li>
            <li>
              <a href="#osechi">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/osechi_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_newYearFooterText">おせち料理</div>
              </a>
            </li>
          </ul>
        </div>
        {/* modal */}
        {modalFlagEvent &&  (
          <CLpModalNewyear onClose={openModalEvent} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitleText u_sp">
                1F ロビー<br />
                <div className="c_lpModalNewyearTitle">
                  もちつき
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/event5.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    1F ロビー<br />
                    <div className="c_lpModalNewyearTitleBold">
                      もちつき
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    お正月飾りに彩られたロビーで、一年の幸福を祈願しながら、お囃子や太鼓とともに威勢よくお餅をつきあげます。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2024年1月1日（元日）<br />
                        11:00～／14:00～ 各回約30分
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ ご予約不要、鑑賞無料
                  </div>
                </div>
              </div>
            </div>
          </CLpModalNewyear>
        )}
        {modalFlag && (
          <CLpModalNewyear onClose={openModal} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitle u_sp">
                想咲おせち<br />
                <div className="c_lpModalNewyearTitleBold">
                  日本料理
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/osechi1.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    想咲おせち<br />
                    <div className="c_lpModalNewyearTitleBold">
                      日本料理
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    日本料理長　岩田 好輝が一品一品素材を選りすぐり、新年の平安を願って作り上げる「想咲おせち」。縁起を担ぐ品々を丁寧に仕込み、美しく盛合せた三段重です。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">ご予約期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2023年12月20日（水）19:00まで
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">料金</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        108,000円
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        15個／要予約
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                  <div className="c_lpModalNewyearButton">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModalNewyear>
        )}
        {modalFlag02 && (
          <CLpModalNewyear onClose={openModal02} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitle u_sp">
                おせち料理 三段<br />
                <div className="c_lpModalNewyearTitleBold">
                  日本料理・フランス料理・中国料理
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/osechi2.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    おせち料理 三段<br />
                    <div className="c_lpModalNewyearTitleBold">
                      日本料理・フランス料理・中国料理
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    日本料理・フランス料理・中国料理を一度に味わえる、幅広い世代の方に好まれる豪華な三段重。多彩な美味とともに、新年の幕開けをお楽しみください。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">ご予約期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2023年12月20日（水）19:00まで
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">料金</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        59,400円
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        100個／要予約
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                  <div className="c_lpModalNewyearButton">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModalNewyear>
        )}
        {modalFlag03 && (
          <CLpModalNewyear onClose={openModal03} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitle u_sp">
                おせち料理 二段<br />
                <div className="c_lpModalNewyearTitleBold">
                  日本料理
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/osechi3.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    おせち料理 二段<br />
                    <div className="c_lpModalNewyearTitleBold">
                      日本料理
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    日本料理長こだわりの品々を種類豊富に詰合せ、手間を惜しまず匠の技で味わい深く仕上げました。少人数のご家庭にも人気のおせち料理です。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">ご予約期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2023年12月20日（水）19:00まで
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">料金</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        41,040円
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        100個／要予約
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                  <div className="c_lpModalNewyearButton">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModalNewyear>
        )}
      </div>
    </LayoutLp>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
